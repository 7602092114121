import React from "react"
import { graphql, Link } from "gatsby"

import Menu from "../components/menu"

const Category = ({ data, pageContext, location }) => {
  var currentCategory = location.pathname.split("/").pop()
  if (!currentCategory) {
    currentCategory = location.pathname.split("/").reverse()[1]
  }

  const products = []

  for (var i = pageContext.result.length - 1; i >= 0; i--) {
    if (pageContext.result[i].node.slider_acf.category === currentCategory) {
      products.push(pageContext.result[i])
    }
  }

  const setClassActive = "tasting"
  const content = pageContext.result

  return (
    <div className="main_wrapper">
      <Menu content={pageContext.result} setClassActive={setClassActive}></Menu>

      <div className="back_button">
        <Link to={"/tasting"} state={{ content }}>
          All products
        </Link>
      </div>
      <div className="page">
        <div className="wrapper">
          <div className="row">
            {products.map((product, i) => (
              <div className="col col-3" key={i}>
                <Link
                  to={"/category/drinks"}
                  state={{ products, currentCategory, content, activeSlide: i }}
                  className={`${
                    setClassActive === "drinks" ? "active" : "inactive"
                  }`}
                >
                  {product.node.slider_acf.productImage ? (
                    <div>
                      <img
                        src={product.node.slider_acf.productImage.sourceUrl}
                        alt=""
                      />
                    </div>
                  ) : (
                    <div></div>
                  )}
                  <h3 className="slide_title">{product.node.title}</h3>
                </Link>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Category

export const postQuery = graphql`
  {
    allWpSlider {
      edges {
        node {
          id
          slug
          title
          slider_acf {
            description
            title
            subTitle
            productImage {
              sourceUrl
            }
            category
            boxContent {
              finish
              nose
              palate
            }
          }
        }
      }
    }
  }
`
